@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");

body {
  margin: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.firebaseui-idp-button {
  /* background-color: rgb(19, 19, 19) !important;
  color: white !important; */
}

.firebaseui-idp-google > .firebaseui-idp-text {
  /* color: white !important; */
}

.mdl-shadow--2dp {
  box-shadow: none !important;
}

.mdl-card .firebaseui-callback-indicator-container {
  display: none !important;
}

.mdl-card.firebaseui-container {
  display: none !important;
}

.firebaseui-id-page-callback {
  background: none !important;
}

.shimmer {
  color: rgb(114, 111, 111);
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  max-width: 200px;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
